/* unplugin-vue-components disabled */import __unplugin_components_12 from '/workspace/src/components/AddContent.vue';
import __unplugin_components_11 from '/workspace/src/components/ButtonCustom.vue';
import __unplugin_components_10 from '/workspace/src/components/EmptyData.vue';
import __unplugin_components_9 from '/workspace/src/components/ButtonCustom.vue';
import __unplugin_components_8 from '/workspace/src/components/ButtonCustom.vue';
import __unplugin_components_7 from '/workspace/src/components/ButtonGroup.vue';
import __unplugin_components_6 from '/workspace/src/components/ImageThumbnail.vue';
import __unplugin_components_5 from '/workspace/src/components/TableSimple.vue';
import __unplugin_components_4 from '/workspace/src/components/ButtonCustom.vue';
import __unplugin_components_3 from '/workspace/src/components/ButtonCustom.vue';
import __unplugin_components_2 from '/workspace/src/components/PageSection.vue';
import __unplugin_components_1 from '/workspace/src/components/PageHeader.vue';
import __unplugin_components_0 from '/workspace/src/components/PageContainerScroll.vue';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(__unplugin_components_0,[_c(__unplugin_components_1,{attrs:{"title":_vm.level.languages[_vm.$i18n.locale].title,"back":""}}),_c(__unplugin_components_2,{attrs:{"loading":_vm.loading}},[(_vm.hasContents)?_c('div',{staticClass:"Controls"},[_c(__unplugin_components_3,{attrs:{"title":_vm.$t('#Button Erase All'),"icon":"Trash2Icon"},on:{"click":function($event){return _vm.onEraseAllContent()}}}),_c(__unplugin_components_4,{directives:[{name:"b-modal",rawName:"v-b-modal.contentModal",modifiers:{"contentModal":true}}],attrs:{"title":_vm.$t('#Content #Button Add Content'),"variant":"primary","icon":"PlusIcon"},on:{"click":function($event){return _vm.onCreateContent()}}})],1):_vm._e(),_c(__unplugin_components_5,{attrs:{"items":_vm.contents,"headers":_vm.headers,"draggable":"","on-drag-change":_vm.onDragContentChange},scopedSlots:_vm._u([{key:"cell(image)",fn:function(ref){
var item = ref.item;
return [_c(__unplugin_components_6,{attrs:{"src":item.coverImageUrl}})]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('h4',[_vm._v(" "+_vm._s(item.languages[_vm.$i18n.locale].title)+" ")])]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(("#Enums #TypeEnum " + (item.type))))+" ")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c(__unplugin_components_7,[_c(__unplugin_components_8,{attrs:{"variant":"icon","icon":"Trash2Icon"},on:{"click":function($event){return _vm.onDeleteContent(item)}}}),_c(__unplugin_components_9,{attrs:{"variant":"icon","icon":"Edit3Icon"},on:{"click":function($event){return _vm.onEditContent(item)}}})],1)]}},{key:"empty",fn:function(){return [_c(__unplugin_components_10,{attrs:{"title":_vm.$t('#EmptyData #No Content Title')}},[_c('span',[_vm._v(_vm._s(_vm.$t('#EmptyData #No Content Message')))]),_c(__unplugin_components_11,{directives:[{name:"b-modal",rawName:"v-b-modal.contentModal",modifiers:{"contentModal":true}}],attrs:{"title":_vm.$t('#Content #Button Add Content'),"variant":"primary","icon":"PlusIcon"},on:{"click":function($event){return _vm.onCreateContent()}}})],1)]},proxy:true}])})],1),_c(__unplugin_components_12,{attrs:{"level":_vm.level,"existing-contents":_vm.contents,"data":_vm.selectedContent.id ? _vm.selectedContent : null},on:{"created":_vm.incorporateCreatedContent},model:{value:(_vm.showContentModal),callback:function ($$v) {_vm.showContentModal=$$v},expression:"showContentModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }