import _objectSpread from "/workspace/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
export default {
  name: 'LevelId',
  data: function data() {
    return {
      loading: true,
      showContentModal: false,
      contents: [],
      selectedContent: {
        languages: {
          'pt-br': {
            title: '',
            name: ''
          }
        },
        files: []
      },
      emptyContent: {
        languages: {
          'pt-br': {
            title: '',
            name: ''
          }
        },
        files: []
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters({
    level: 'level/selectedLevel'
  })), {}, {
    hasContents: function hasContents() {
      return this.contents.length > 0;
    },
    headers: function headers() {
      return [{
        name: 'image',
        title: '',
        cellClass: 'thumbnail'
      }, {
        name: 'name',
        title: this.$t('#Content #TableHeader Name'),
        style: {
          width: '35%'
        }
      }, {
        name: 'type',
        title: this.$t('#Content #TableHeader Type'),
        style: {
          width: '30%'
        }
      }, {
        name: 'actions',
        title: '',
        style: {
          width: '10%'
        }
      }];
    }
  }),
  watch: {
    level: function level() {
      if (this.level) {
        var contents = this.level.contents;
        this.contents = _.chain(contents).filter(function (item) {
          return item.status !== 'deleted';
        }).sortBy(function (item) {
          return item.order;
        }).value();

        _.forEach(this.contents, function (item) {
          if (!Object.keys(item).includes('questions')) {
            // eslint-disable-next-line no-param-reassign
            item.questions = [];
          }
        });
      }
    }
  },
  beforeMount: function beforeMount() {
    var _this = this;

    this.$store.dispatch('level/findById', this.$route.params.levelId).then(function () {
      _this.loading = false;
    });
  },
  methods: _objectSpread(_objectSpread({}, mapActions({
    softDelete: 'content/softDelete',
    update: 'content/update'
  })), {}, {
    incorporateCreatedContent: function incorporateCreatedContent(content) {
      var index = this.contents.findIndex(function (item) {
        return item.id === content.id;
      });

      if (index >= 0) {
        Object.assign(this.contents[index], content);
      } else {
        this.contents.push(content);
      }
    },
    onCreateContent: function onCreateContent() {
      this.selectedContent = _.cloneDeep(this.emptyContent);
      this.showContentModal = !this.showContentModal;
    },
    onEditContent: function onEditContent(content) {
      this.selectedContent = _.cloneDeep(content);
      this.showContentModal = !this.showContentModal;
    },
    onDragContentChange: function onDragContentChange() {
      var _this2 = this;

      var contentsOrdered = this.contents.map(function (item, index) {
        var content = item;
        content.order = index;
        content.levelId = Number(_this2.$route.params.levelId);
        return content;
      });
      contentsOrdered.forEach(function (content) {
        _this2.update({
          id: content.id,
          content: content
        });
      });
    },
    onDeleteContent: function onDeleteContent(content) {
      var _this3 = this;

      this.$swal({
        icon: 'warning',
        title: this.$t('#SweetAlertTitle Delete Content'),
        html: this.$t('#SweetAlertDescription Delete Content'),
        showCancelButton: true,
        cancelButtonText: this.$t('#SweetAlertButton Cancel'),
        confirmButtonText: this.$t('#SweetAlertButton Confirm'),
        customClass: {
          confirmButton: 'btn btn-secondary color-secondary',
          cancelButton: 'btn btn-danger ml-1'
        }
      }).then(function (result) {
        if (result.isConfirmed) {
          _this3.softDelete(content.id).then(function () {
            // Remove item from array
            _this3.contents = _.chain(_this3.contents).filter(function (item) {
              return item.id !== content.id;
            }).value();

            _this3.$swal({
              icon: 'success',
              title: _this3.$t('#SweetAlertTitle Success'),
              html: _this3.$t('#SweetAlertDescription Success'),
              showConfirmButton: false,
              timer: 1500
            });

            _this3.onDragContentChange();
          }).catch(function () {
            _this3.$swal({
              icon: 'error',
              title: _this3.$t('#SweetAlertTitle Error'),
              html: _this3.$t('#SweetAlertDescription Error'),
              confirmButtonText: _this3.$t('#SweetAlertButton Confirm'),
              showCancelButton: false,
              customClass: {
                confirmButton: 'btn btn-outline-danger background-primary'
              }
            });
          });
        }
      });
    },
    onEraseAllContent: function onEraseAllContent() {
      var _this4 = this;

      this.$swal({
        icon: 'warning',
        title: this.$t('#SweetAlertTitle Erase All Contents'),
        html: this.$t('#SweetAlertDescription Erase All Contents'),
        showCancelButton: true,
        cancelButtonText: this.$t('#SweetAlertButton Cancel'),
        confirmButtonText: this.$t('#SweetAlertButton Confirm'),
        customClass: {
          confirmButton: 'btn btn-secondary color-secondary',
          cancelButton: 'btn btn-danger ml-1'
        }
      }).then(function (result) {
        if (result.isConfirmed) {
          var deletePromises = _this4.contents.map(function (content) {
            return _this4.softDelete(content.id);
          });

          Promise.all(deletePromises).then(function () {
            _this4.$swal({
              icon: 'success',
              title: _this4.$t('#SweetAlertTitle Success'),
              html: _this4.$t('#SweetAlertDescription Success'),
              showConfirmButton: false,
              timer: 1500
            });
          }).catch(function () {
            _this4.$swal({
              icon: 'error',
              title: _this4.$t('#SweetAlertTitle Error'),
              html: _this4.$t('#SweetAlertDescription Error'),
              confirmButtonText: _this4.$t('#SweetAlertButton Confirm'),
              showCancelButton: false,
              customClass: {
                confirmButton: 'btn btn-outline-danger background-primary'
              }
            });
          });
        }
      });
    }
  })
};